.contact-form {
  height: max-content;
  width: auto;
  margin-top: 60px;
}

.contact-form-container {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10px;
}

.contact-form-left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 5px;
}

.custom-message-box {
  height: 250px;
  width: auto;

  border: #283755 solid 1px;
  border-radius: 2px;
}

.contact-form-control {
  border: #000 solid 1px !important;
  border-radius: 2px;
  width: 200px;
}

.contact-form-right-side {
  width: 100%;
}

.contact-form-submit-button {
  background-color: #4b6ccc;
  width: 230px;
  height: auto;
  border-radius: 4px;
  padding: 10px 7px;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end;

  transition: all 0.3s ease;
}

.contact-form-submit-button:active {
  transform: scale(0.95);
  background-color: #e5d5cf;
}

.contact-form-button-text {
  color: #fff;
  text-align: center;
  margin: auto auto;
}

@media (min-width: 3000px) {
  .custom-message-box {
    height: 350px;
    width: 600px;

    border: #283755 solid 1px;
    border-radius: 2px;

    font-size: 28px;
  }

  .contact-form-control {
    border: #000 solid 1px !important;
    border-radius: 2px;
    width: 350px;
    height: 60px;
    font-size: 28px;
  }
  .contact-form-submit-button {
    background-color: #4b6ccc;
    width: 230px;
    height: auto;
    border-radius: 4px;
    padding: 10px 7px;
    cursor: pointer;
    margin-top: 10px;
    align-self: flex-end;

    transition: all 0.3s ease;
    font-size: 26px;
  }
}

@media (max-width: 475px) {
  .contact-form-container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
  }

  .contact-form-control {
    border: #000 solid 1px !important;
    border-radius: 2px;
    width: 100%;
  }

  .custom-message-box {
    height: 250px;
    width: 100%;
    border: #283755 solid 1px;
    border-radius: 2px;
  }

  .contact-form-left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 5px;
    width: 100%;
  }

  .contact-form-submit-button {
    background-color: #4b6ccc;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 10px 7px;
    cursor: pointer;
    margin-top: 10px;
    align-self: flex-end;

    transition: all 0.3s ease;
  }
}

.form-control {
  background: none !important;
  border: none !important;
  box-shadow: none !important; /* Removes the blue glow on focus */
  border-radius: 0 !important; /* If you want to remove rounded corners */
}

.contact-form-control {
  height: 50px;
  padding-top: 30px;
  padding-bottom: 5px;
}

.contact-form-control::placeholder {
  line-height: 10px;
  height: 10px;
  padding-top: 0;
  padding-bottom: 5px;
}

/* Dashed underline */
.form-control:active,
.form-control:focus,
.form-control {
  border-bottom: 1px dashed #000 !important;
  outline: none !important;
}

.form-row {
  display: flex;
  flex-direction: row;
  width: max-content;
}

.popup-contact-form {
  width: 380px;
  min-height: 450px;
  height: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000;

  border-radius: 20px;
  border: 5px solid #99e7b8;
  background-color: #d9d9d9;

  text-transform: uppercase;

  box-shadow: -5px 10px 20px rgba(84, 84, 84, 0.692);
}

.close-popup-contact-form {
  position: absolute;
  top: 0%;
  right: 0%;
}
